/* eslint-disable no-console */
import { apiConfig, loginRequest } from "../authConfig";
import { msalInstance } from "../index";

export async function callApi(accessToken?: string) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiConfig.uri, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function subscribe(accessToken?: string) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    console.log(response);

    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "POST",
    headers: headers,
  };

  return fetch(`${apiConfig.uri}/api/subscribe`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function signalsHistory(accessToken?: string) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    console.log(response);

    accessToken = response.idToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`${apiConfig.uri}/api/user/dashboard`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export const contactUsApi = async (postData: any) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  };
  return fetch(
    `${apiConfig.contactUs}/workflows/04e669ced9d44721a29baf12b8959e83/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2ftriggers%2fmanual%2frun&sv=1.0&sig=pPZmgSbTFQnfbGlCrxc9nvIN7TgGNLDebCSQ0hqxZx4`,
    options
  )
    .then((response) => {
      if (response.status === 202) {
        // Handle the 202 response here
        console.log("202 Accepted - Request accepted for processing");
        // return "Thank you we will get back to you soon..";
        return;
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error("something went wrong");
    });
};
