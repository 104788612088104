import { EventType, PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { msalConfig } from "./authConfig";
import "./index.css";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const account = event.payload as any;
    if (account) {
      msalInstance.setActiveAccount(account.account);
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App pca={msalInstance} />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
