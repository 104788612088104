export const Footer = () => {
  return (
    <footer>
      {/* <!-- footer content begin --> */}
      <div className="uk-section uk-section-primary uk-padding-large uk-padding-remove-horizontal uk-margin-medium-top">
        <div className="uk-container">
          <div className="uk-grid uk-flex uk-flex-center" data-uk-grid>
            <div className="uk-width-1-2@m in-copyright-text">
              <p>© Cygnuss Inc 2023. All rights reserved.</p>
            </div>
            <div className="uk-width-1-2@m uk-text-right@m in-footer-socials">
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="#">
                <i className="fab fa-facebook-square"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer content end --> */}
      {/* <!-- module totop begin --> */}
      <div className="uk-visible@m">
        <a href="#" className="in-totop fas fa-chevron-up" data-uk-scroll></a>
      </div>
      {/* <!-- module totop begin --> */}
    </footer>
  );
};
