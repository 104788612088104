import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "uikit-react";
import logo from "../img/stockImage.jpg";
import "./../css/style.css";

export const TradingProducts = () => {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      {/* // <!-- section content begin --> */}
      <div className="uk-section uk-section-muted in-padding-large-vertical@s in-profit-9">
        <div className="uk-container">
          <div className="uk-grid-divider" data-uk-grid>
            <div className="uk-width-expand@m in-margin-top-20@s">
              <h2>Get Ahead of the Game with our Real Time Trading Signals.</h2>
              <p>
                Best in class machine learning algorithms that leverage
                trend-following techniques and advanced AI pattern recognition.
              </p>
              <p>
                Designed to cater to various trading styles, Cygnus is highly
                versatile, supporting intraday, swing, and long-term trading
                strategies. The signals are meticulously charted, providing a
                clear visual representation of market trends and enabling you to
                make well-informed decisions. Additionally, you can receive
                these signals in real-time through convenient channels such as
                text messages and emails, ensuring you never miss a trading
                opportunity.
              </p>
            </div>
            <div className="uk-width-expand@m in-margin-top-20@s">
              <img
                className="uk-margin-small-bottom"
                src={logo}
                data-src={logo}
                alt="logo"
                data-uk-img
              />
            </div>
          </div>
          <div className="uk-grid-divider" data-uk-grid>
            <div className="uk-width-expand@m in-margin-top-20@s uk-flex uk-flex-center">
              <p>
                <Button
                  className={`uk-button uk-button-default uk-border-rounded ${
                    hovered ? "hovered" : ""
                  }`}
                  component={RouterLink}
                  to="/free-trial"
                  href="/free-trial"
                  style={{
                    backgroundColor: hovered ? "transparent" : "#fd6a4f",
                    borderColor: "#fd6a4f",
                    color: "white", // Set the text color to white
                    transition:
                      "background-color 0.3s, border-color 0.3s, color 0.3s",
                  }}
                  onClick={() => {
                    // Add your click functionality here
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  Start Free Trail
                </Button>
              </p>
            </div>
          </div>
          <div className="uk-grid-divider" data-uk-grid>
            <div className="uk-width-expand@m in-margin-top-20@s">
              <div>
                <h2>Trading products</h2>
                <p>
                  Choose from 6 asset class names and get access to 500+ trading
                  instruments
                </p>
              </div>
            </div>
            <div className="uk-width-2-3@m">
              <div
                className="uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-6@m uk-text-center"
                data-uk-grid
              >
                <div>
                  <i className="fas fa-euro-sign in-icon-wrap circle uk-box-shadow-small"></i>
                  <p className="uk-text-bold uk-margin-small-top">Forex</p>
                </div>
                <div>
                  <i className="fab fa-btc in-icon-wrap circle uk-box-shadow-small"></i>
                  <p className="uk-text-bold uk-margin-small-top">Crypto</p>
                </div>
                <div>
                  <i className="fas fa-chart-area in-icon-wrap circle uk-box-shadow-small"></i>
                  <p className="uk-text-bold uk-margin-small-top">Indexes</p>
                </div>
                <div>
                  <i className="fas fa-file-contract in-icon-wrap circle uk-box-shadow-small"></i>
                  <p className="uk-text-bold uk-margin-small-top">Stocks</p>
                </div>
                <div>
                  <i className="fas fa-tint in-icon-wrap circle uk-box-shadow-small"></i>
                  <p className="uk-text-bold uk-margin-small-top">Energy</p>
                </div>
                <div>
                  <i className="fas fa-cube in-icon-wrap circle uk-box-shadow-small"></i>
                  <p className="uk-text-bold uk-margin-small-top">
                    Commodities
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- section content end --> */}
      {/* // <!-- section content begin --> */}
      {/*
        <div
          className="uk-section uk-section-secondary uk-padding-large in-padding-large-vertical@s uk-background-contain uk-background-bottom-center in-profit-11"
          data-src="img/in-section-profit-11.png"
          data-uk-img
        >
          <div className="uk-container">
            <div className="uk-grid uk-flex uk-flex-center">
              <div className="uk-width-5-6@m">
                <div className="uk-grid" data-uk-grid>
                  <div className="uk-width-1-2@m">
                    <span className="uk-label uk-text-small uk-text-uppercase uk-border-pill uk-margin-small-bottom">
                      Announcing
                    </span>
                    <h2 className="uk-margin-small-top">
                      <span className="uk-heading-small">$4.95</span> online
                      stocks, currencies &amp; commodities trades
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt labore dolore magna aliqua.
                    </p>
                    <a
                      href="#"
                      className="uk-button uk-button-primary uk-border-rounded uk-margin-top"
                    >
                      Learn more
                    </a>
                  </div>
                  <div className="uk-width-1-2@m">
                    <div className="uk-card uk-card-primary uk-border-rounded ">
                      <div className="uk-card-body">
                        <h3 className="uk-margin-bottom">
                          New to investing? Start here.
                        </h3>
                        <form className="uk-grid-small" data-uk-grid>
                          <div className="uk-width-1-1">
                            <input
                              className="uk-input uk-border-rounded"
                              type="text"
                              placeholder="Full name"
                            />
                          </div>
                          <div className="uk-width-1-1">
                            <input
                              className="uk-input uk-border-rounded"
                              type="text"
                              placeholder="Email address"
                            />
                          </div>
                          <div className="uk-width-1-1">
                            <input
                              className="uk-input uk-border-rounded"
                              type="text"
                              placeholder="Phone number"
                            />
                          </div>
                          <div className="uk-width-1-1">
                            <button className="uk-button uk-button-primary uk-border-rounded uk-width-expand uk-margin-small-bottom">
                              Create Account
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
      {/* <!-- section content end --> */}
      {/* <!-- section content begin --> */}

      {/*
        <div className="uk-section uk-padding-large in-padding-large-vertical@s in-profit-12">
          <div className="uk-container">
            <div className="uk-grid-large uk-flex uk-flex-center" data-uk-grid>
              <div className="uk-width-1-2@m uk-text-center">
                <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-medium">
                  <div className="uk-card-body">
                    <table className="uk-table uk-table-striped">
                      <thead>
                        <tr>
                          <th className="uk-text-center">Instrument</th>
                          <th className="uk-text-center">Bid</th>
                          <th className="uk-text-center">Ask</th>
                          <th className="uk-text-center uk-visible@s">Spread</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              className="uk-margin-small-right"
                              src="img/in-lazy.svg"
                              data-src="img/in-profit-fxeur.svg"
                              alt="fx-flag"
                              width="29"
                              height="17"
                              data-uk-img
                            />
                            <span className="in-pairname">EURUSD</span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-danger uk-border-pill">
                              1.09554
                            </span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-danger uk-border-pill">
                              1.09555
                            </span>
                          </td>
                          <td className="uk-visible@s">0.1</td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              className="uk-margin-small-right"
                              src="img/in-lazy.svg"
                              data-src="img/in-profit-fxaud.svg"
                              alt="fx-flag"
                              width="29"
                              height="17"
                              data-uk-img
                            />
                            <span className="in-pairname">AUDUSD</span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-danger uk-border-pill">
                              0.67017
                            </span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-success uk-border-pill">
                              0.67019
                            </span>
                          </td>
                          <td className="uk-visible@s">0.2</td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              className="uk-margin-small-right"
                              src="img/in-lazy.svg"
                              data-src="img/in-profit-fxjpy.svg"
                              alt="fx-flag"
                              width="29"
                              height="17"
                              data-uk-img
                            />
                            <span className="in-pairname">USDJPY</span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-success uk-border-pill">
                              109.792
                            </span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-danger uk-border-pill">
                              109.793
                            </span>
                          </td>
                          <td className="uk-visible@s">0.0</td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              className="uk-margin-small-right"
                              src="img/in-lazy.svg"
                              data-src="img/in-profit-fxcad.svg"
                              alt="fx-flag"
                              width="29"
                              height="17"
                              data-uk-img
                            />
                            <span className="in-pairname">USDCAD</span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-success uk-border-pill">
                              1.32900
                            </span>
                          </td>
                          <td>
                            <span className="uk-label uk-label-success uk-border-pill">
                              1.32909
                            </span>
                          </td>
                          <td className="uk-visible@s">0.3</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <a className="uk-text-uppercase uk-text-small" href="#">
                  See all instruments
                </a>
              </div>
              <div className="uk-width-1-2@m">
                <h2>Live Fx &amp; Spot Metal Quotes</h2>
                <p>
                  Quis autem vel eum iure reprehenderit qui in ea voluptate velit
                  esse nihil molestiae consequatu vel illum qui dolorem.
                </p>
                <ul className="uk-list uk-list-bullet in-list-check uk-margin-bottom">
                  <li>Ultra-competitive pricing</li>
                  <li>Trading flexibility</li>
                  <li>Award-winning platform</li>
                </ul>
                <h5 className="uk-heading-bullet ">
                  Trade wherever you are, whenever you want to.
                </h5>
                <div data-uk-margin>
                  <a
                    href="#"
                    className="uk-button in-button-app uk-margin-small-right"
                  >
                    <i className="fab fa-google-play fa-2x"></i>
                    <span className="wrapper">
                      Download from<span>Play Store</span>
                    </span>
                  </a>
                  <a href="#" className="uk-button in-button-app">
                    <i className="fab fa-apple fa-2x"></i>
                    <span className="wrapper">
                      Download from<span>App Store</span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="uk-width-5-6@m in-offset-bottom-40">
                <div
                  className="uk-grid-divider uk-child-width-1-2@s uk-child-width-1-4@m uk-margin-top uk-text-center"
                  data-uk-grid
                >
                  <div>
                    <h2>&lt; 7.12 ms</h2>
                    <p className="uk-text-uppercase uk-text-small uk-text-primary">
                      Average order execution speed
                    </p>
                  </div>
                  <div>
                    <h2>12+</h2>
                    <p className="uk-text-uppercase uk-text-small uk-text-primary">
                      Integrated liquidity providers
                    </p>
                  </div>
                  <div>
                    <h2>&gt; 12,000</h2>
                    <p className="uk-text-uppercase uk-text-small uk-text-primary">
                      Executed orders per second
                    </p>
                  </div>
                  <div>
                    <h2>$545 k</h2>
                    <p className="uk-text-uppercase uk-text-small uk-text-primary">
                      Average trading volume per day
                    </p>
                  </div>
                </div>
                <div className="uk-text-center uk-margin-medium-top">
                  <a
                    href="#"
                    className="uk-button uk-button-primary uk-border-rounded"
                  >
                    Setup your trading account
                  </a>
                  <a
                    href="#"
                    className="uk-button uk-button-default uk-border-rounded uk-margin-small-left"
                  >
                    Discover our platform
                  </a>
                  <p>Registration takes only 40 seconds!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
      {/* <!-- section content end --> */}
    </>
  );
};
