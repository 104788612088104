import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import { signalsHistory } from "../utils/ApiCall";

export interface Signal {
  symbol: string;
  signalTime: string;
  signalPrice: number;
  positionType: string;
  signalType: string;
  closingPrice: number;
  pnl: number;
  pnlPct: number;
  isOpen: boolean;
}

export const Dashboard = () => {
  const { instance, inProgress } = useMsal();
  const [apiData, setApiData] = useState<Signal[] | null>(null);

  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      signalsHistory()
        .then((response) => setApiData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() || undefined,
            });
          }
        });
    }
  }, [inProgress, apiData, instance]);

  return (
    <>
      <div className="uk-section uk-section-muted in-padding-large-vertical@s in-profit-9">
        <div className="uk-container">
          <h2>Signals History</h2>
          {!apiData && <>Loading...</>}
          {apiData && (
            <table className="uk-table uk-table-small uk-table-middle uk-table-responsive">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Signal Date</th>
                  <th>Position</th>
                  <th>Open Price</th>
                  <th>Closing Price</th>
                  <th>P&L</th>
                  <th>P&L %</th>
                  <th>Open</th>
                </tr>
                {apiData.map((signal) => {
                  return (
                    <tr
                      key={signal.symbol}
                      className={
                        signal.pnl > 0 ? "uk-alert-success" : "uk-alert-danger"
                      }
                    >
                      <td>{signal.symbol}</td>
                      <td>{signal.signalTime}</td>
                      <td>{signal.positionType}</td>
                      <td align="right">{signal.signalPrice}</td>
                      <td align="right">{signal.closingPrice}</td>
                      <td align="right">{signal.pnl}</td>
                      <td align="right">{signal.pnlPct}</td>
                      <td>{signal.isOpen ? "Yes" : "No"}</td>
                    </tr>
                  );
                })}
              </thead>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
