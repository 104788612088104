import logo from "../../img/logo/logo-text-on-dark.svg";

export const Footer = () => {
  return (
    <footer>
      {/* <!-- footer content begin --> */}
      <div className="uk-section uk-section-primary uk-padding-large uk-padding-remove-horizontal uk-margin-medium-top">
        <div className="uk-container">
          <div
            className="uk-child-width-1-2@s uk-child-width-1-4@m uk-flex"
            data-uk-grid
          >
            {/*
            <div>
              <h4 className="uk-heading-bullet">Overview</h4>
              <ul className="uk-list uk-link-text">
                <li>
                  <a href="#">Stock indices</a>
                </li>
                <li>
                  <a href="#">Commodities</a>
                </li>
                <li>
                  <a href="#">Forex</a>
                </li>
              </ul>
            </div>
             */}
            <div>
              <h4 className="uk-heading-bullet">Company</h4>
              <ul className="uk-list uk-link-text">
                <li>
                  <a href="/about-us">About</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="uk-heading-bullet">Legal</h4>
              <ul className="uk-list uk-link-text">
                <li>
                  <a href="/terms-and-conditions">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy &amp; Policy</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
            {/* 
            <div className="uk-visible@m">
              <h4 className="uk-heading-bullet">Support</h4>
              <ul className="uk-list uk-link-text">
                <li>
                  <a href="#">Documentation</a>
                </li>
              </ul>
            </div>
             */}
            <div className="uk-flex-first uk-flex-last@m uk-flex-1">
              <ul className="uk-list uk-link-text  uk-align-right">
                <li>
                  <img
                    className="uk-margin-small-bottom"
                    src={logo}
                    data-src={logo}
                    alt="logo"
                    width="130"
                    height="36"
                    data-uk-img
                  />
                </li>
                {/* <li>
                  <a href="#">
                    <i className="fas fa-envelope uk-margin-small-right"></i>
                    info@cygnuss.com
                  </a>
                </li> */}
                <li>
                  <a href="#">
                    <i className="fas fa-map-marker-alt uk-margin-small-right"></i>
                    Florida, USA
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="uk-grid uk-flex uk-flex-center uk-margin-large-top"
            data-uk-grid
          >
            {/* 
            <div className="uk-width-5-6@m uk-margin-bottom">
              <div className="in-footer-warning in-margin-top-20@s">
                <h5 className="uk-text-small uk-text-uppercase">
                  <span>Risk Warning</span>
                </h5>
                <p className="uk-text-small">
                  Trading derivatives and leveraged products carries a high
                  level of risk, including the risk of losing substantially more
                  than your initial investment. It is not suitable for everyone.
                  Before you make any decision in relation to a financial
                  product you should obtain and consider our Product Disclosure
                  Statement (PDS) and Financial Services Guide (FSG) available
                  on our website and seek independent advice if necessary{" "}
                </p>
              </div>
            </div>
             */}
            <div className="uk-width-1-2@m in-copyright-text">
              <p>© Cygnus Inc 2023. All rights reserved.</p>
            </div>
            <div className="uk-width-1-2@m uk-text-right@m in-footer-socials">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/profile.php?id=61553486780130"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/direct/inbox/"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/home"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/marketing-cygnuss-81062729a"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer content end --> */}
      {/* <!-- module totop begin --> */}
      <div className="uk-visible@m">
        <a href="#" className="in-totop fas fa-chevron-up" data-uk-scroll></a>
      </div>
      {/* <!-- module totop begin --> */}
    </footer>
  );
};
