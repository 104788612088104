import { useState } from "react";
import { Button, Input, Textarea } from "uikit-react";
import { MainLayout } from "../layout/MainLayout";
import { contactUsApi } from "../utils/ApiCall";
import "./../css/style.css";

export const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    body: "",
  });

  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      await contactUsApi(formData)
        .then((response) => {
          setResponse("Thank you we will get back to you soon..");
          setSuccess(true);
        })
        .catch((e) => setError(e));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainLayout>
      <div className="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
        <div
          className="uk-card uk-card-default uk-card-body uk-box-shadow-medium uk-width-1-2@s uk-border-rounded "
          style={{ backgroundColor: "#EAECEE" }}
        >
          <h2 className="uk-card-title uk-text-center">Contact Us</h2>

          {success && (
            <div>
              <p style={{ color: "green" }}>
                {JSON.stringify(response, null, 2)}
              </p>
            </div>
          )}

          {error && (
            <div className="uk-text-center">
              <p style={{ color: "red" }}>{"something went Wrong"}</p>
            </div>
          )}

          <form>
            <div className="uk-margin">
              <label className="uk-form-label">Name</label>
              <Input
                className="uk-input  uk-border-rounded"
                type="text"
                name="name"
                value={formData.name}
                // style={{ borderRadius: "10px" }}
                onChange={handleChange}
                required
              />
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">Email</label>
              <Input
                className="uk-input  uk-border-rounded"
                type="email"
                name="email"
                value={formData.email}
                // style={{ borderRadius: "10px" }}
                onChange={handleChange}
                required
              />
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">Subject</label>
              <Input
                className="uk-input  uk-border-rounded"
                type="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">Message</label>
              <Textarea
                className="uk-textarea  uk-border-rounded"
                name="body"
                value={formData.body}
                onChange={handleChange}
                required
              />
            </div>

            <Button
              className="uk-button uk-button-primary uk-border-rounded register"
              style={{
                border: "2px solid #fd6a4f",
                backgroundColor: "transparent",
                color: "#fd6a4f",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};
