import { useEffect, useState } from "react";

// Msal imports
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

// Sample app imports
import { ErrorComponent } from "../ui-components/ErrorComponent";
import { Loading } from "../ui-components/Loading";
import { subscribe } from "../utils/ApiCall";

// Material-ui imports

const SubscribeContent = () => {
  const { instance, inProgress } = useMsal();
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      subscribe()
        .then((response) => setApiData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() || undefined,
            });
          }
        });
    }
  }, [inProgress, apiData, instance]);

  return <>Subscribed</>;
};

export function Subscribe() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <SubscribeContent />
    </MsalAuthenticationTemplate>
  );
}
