import { useMsal } from "@azure/msal-react";
import { Button } from "uikit-react";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleSignOut = async (loginType: string) => {
    if (loginType === "popup") {
      await instance.logoutPopup();
    } else if (loginType === "redirect") {
      await instance.logoutRedirect();
    }
  };

  return (
    <Button
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(e: any) => {
        e.preventDefault();
        handleSignOut("popup");
      }}
      key="loginRedirect"
    >
      Logout
    </Button>
  );
};
