import {
  Container,
  NavItem,
  Navbar,
  NavbarContainer,
  NavbarSticky,
  Section,
} from "uikit-react";
import logo from "../../img/logo/logo-text.svg";
import SignInSignOutButton from "../../ui-components/SignInSignOutButton";
import { SignUpButton } from "../../ui-components/SignUpButton";

function Header() {
  return (
    <header>
      {/* <!-- header content begin --> */}
      {/* <div className="uk-section uk-padding-small in-profit-ticker">
        <div className="uk-container">
          <div className="uk-grid">
            <div className="uk-width-1-1">
              <div data-uk-slider="autoplay: true; autoplay-interval: 5000">
                <ul
                  className="uk-grid-large uk-slider-items uk-child-width-1-3@s uk-child-width-1-6@m uk-text-center"
                  data-uk-grid
                >
                  <li>
                    <i className="fas fa-angle-up in-icon-wrap small circle up"></i>
                    XAUUSD <span className="uk-text-success">1478.81</span>
                  </li>
                  <li>
                    <i className="fas fa-angle-down in-icon-wrap small circle down"></i>
                    GBPUSD <span className="uk-text-danger">1.3191</span>
                  </li>
                  <li>
                    <i className="fas fa-angle-down in-icon-wrap small circle down"></i>
                    EURUSD <span className="uk-text-danger">1.1159</span>
                  </li>
                  <li>
                    <i className="fas fa-angle-up in-icon-wrap small circle up"></i>
                    USDJPY <span className="uk-text-success">109.59</span>
                  </li>
                  <li>
                    <i className="fas fa-angle-up in-icon-wrap small circle up"></i>
                    USDCAD <span className="uk-text-success">1.3172</span>
                  </li>
                  <li>
                    <i className="fas fa-angle-up in-icon-wrap small circle up"></i>
                    USDCHF <span className="uk-text-success">0.9776</span>
                  </li>
                  <li>
                    <i className="fas fa-angle-down in-icon-wrap small circle down"></i>
                    AUDUSD <span className="uk-text-danger">0.67064</span>
                  </li>
                  <li>
                    <i className="fas fa-angle-up in-icon-wrap small circle up"></i>
                    GBPJPY <span className="uk-text-success">141.91</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Section className="uk-section uk-padding-remove-vertical">
        <Container>
          <NavbarSticky>
            <NavbarContainer transparent>
              <Navbar left>
                <NavItem>
                  <div className="uk-navbar-item">
                    {/* <!-- module logo begin --> */}
                    <a className="uk-logo" href={"/"}>
                      <img
                        className="in-offset-top-10"
                        src={logo}
                        data-src={logo}
                        alt="logo"
                        width="130"
                        height="36"
                        data-uk-img
                      />
                    </a>
                    {/* <!-- module logo begin --> */}
                  </div>
                </NavItem>
              </Navbar>
              <Navbar right>
                <NavItem className="in-optional-nav">
                  <div>
                    <SignUpButton />
                  </div>
                </NavItem>

                <NavItem className="in-optional-nav">
                  <div>
                    <SignInSignOutButton />
                  </div>
                </NavItem>
              </Navbar>
            </NavbarContainer>
          </NavbarSticky>
        </Container>
      </Section>
      {/* <!-- header content end --> */}
    </header>
  );
}

export default Header;
