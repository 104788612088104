import { MainLayout } from "../layout/MainLayout";

export const AboutUs = () => {
  return (
    <MainLayout>
      {/* // <!-- section content begin --> */}
      <div className="uk-section uk-section-muted in-padding-large-vertical@s in-profit-9">
        <div className="uk-container">
          <div className="uk-grid-divider" data-uk-grid>
            <div className="uk-width-expand@m in-margin-top-20@s">
              <div>
                <h2>About</h2>
              </div>
              <p>
                Cygnus is a cutting-edge software development company that
                specializes in real-time trading signal solutions utilizing
                advanced machine learning algorithms. We are dedicated to
                creating and implementing machine learning algorithms that
                leverage trend-following techniques and advanced AI pattern
                recognition. Our focus is on providing real-time trading signals
                for our clients.
              </p>
              <p>
                Cygnus offers a powerful and dynamic trading solution that
                delivers real-time, buy/sell signals tailored to your specific
                watchlist of stocks, ETFs, options, futures, currencies, and
                more. Cygnus also provides our own recommendations for high
                volume securities which our system diligently tracks to offer
                instant buy/sell signals as they occur.
              </p>
              <p>
                Cygnus creates signals that are meticulously charted, providing
                a clear visual representation of market trends and enabling you
                to make well-informed decisions. Additionally, you can receive
                these signals in real-time through convenient channels such as
                text messages and emails, ensuring you never miss a trading
                opportunity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
