import { Footer } from "./user/Footer";
import Header from "./user/Header";

export interface LayoutProps {
  children: React.ReactNode;
}

export const UserLayout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      <main children={children}></main>
      <Footer />
    </>
  );
};
