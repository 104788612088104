import {
  AccountInfo,
  EventType,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js";
import "./App.css";
import { b2cPolicies, forgotPasswordRequest } from "./authConfig";
import "./css/style.css";
import { AboutUs } from "./pages/AboutUs";
import { ContactUs } from "./pages/Contactus";
import { FreeTrial } from "./pages/FreeTrial";
import Home from "./pages/Home";
import { Logout } from "./pages/Logout";
import { Subscribe } from "./pages/Subscibe";
import { TermsAndConditions } from "./pages/T&C";
import { TradingProducts } from "./pages/TradingProducts";
import { CustomNavigationClient } from "./utils/NavigationClient";

function App({ pca }: { pca: PublicClientApplication }) {
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <Pages />
      </MsalProvider>
    </ClientSideNavigation>
  );
}

function Pages() {
  const { instance } = useMsal();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callbackId = instance.addEventCallback((event: any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims &&
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["tfp"] === b2cPolicies.names.signIn
            );

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error?.message &&
          event.error.message.indexOf("AADB2C90118") > -1
        ) {
          instance
            .loginRedirect(forgotPasswordRequest as RedirectRequest)
            .then(() => {
              window.alert(
                "Password has been reset successfully. \nPlease sign-in with your new password."
              );
            });
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Routes>
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/logout" element={<Logout />} />
        <Route index path="/" element={<Home />} />
        <Route index path="/about-us" element={<AboutUs />} />
        <Route index path="/contact-us" element={<ContactUs />} />
        <Route
          index
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        />
        <Route index path="/trading-products" element={<TradingProducts />} />
        <Route index path="/free-trial" element={<FreeTrial />} />
      </Routes>
    </>
  );
}

export default App;

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
function ClientSideNavigation({
  pca,
  children,
}: {
  pca: PublicClientApplication;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}
