import { MainLayout } from "../layout/MainLayout";

export const TermsAndConditions = () => {
  return (
    <MainLayout>
      {/* // <!-- section content begin --> */}
      <div className="uk-section uk-section-muted in-padding-large-vertical@s in-profit-9">
        <div className="uk-container">
          <div className="uk-grid-divider" data-uk-grid>
            <div className="uk-width-expand@m in-margin-top-20@s">
              <h2>Terms and Conditions</h2>
              <p>Last updated: October 22, 2023</p>
              <p>
                CygnusCues is an online financial digital media platform that
                publishes content (Signals) relating to financial markets and
                provides certain products and services through
                https://cygnuscues.com and via text messages, email and other
                electronic communications. Cygnus and its corresponding services
                are wholly owned and operated by Cygnus Cues LLC, Wellington,
                Florida.
              </p>
              <p>
                Please read these terms and conditions carefully before using
                Our Service.
              </p>
              <p>
                <h3>Interpretation and Definitions</h3>
                <b>Interpretation</b>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <b>Definitions</b>
                <p>For the purposes of these Terms and Conditions:</p>
                <p>
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where control means
                  ownership of 50% or more of the shares, equity interest or
                  other securities entitled to vote for election of directors or
                  other managing authority.
                </p>
                <p>
                  Account means a unique account created for You to access our
                  Service or parts of our Service.
                </p>
                <p>Country refers to: United States.</p>
                <p>
                  Company{" "}
                  {
                    '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement)'
                  }{" "}
                  refers to Cygnus Cues, 3147 Hamlin Way, Wellington, Florida
                  33414.
                </p>
                <p>
                  Device means any device that can access the Service such as a
                  computer, a cell phone or a digital tablet.
                </p>

                <p>
                  Feedback means feedback, innovations or suggestions sent by
                  You regarding the attributes, performance or features of our
                  Service. Free Trial refers to a limited period of time that
                  may be free when purchasing a Subscription.
                </p>
                <p>Service refers to the Website.</p>
                <p>
                  Signals refers to the output of mathematical or statistical
                  models used in the context of financial markets.
                </p>
                <p>
                  Subscriptions refer to the services or access to the Service
                  offered on a subscription basis by the Company to You.
                </p>
                <p>
                  Terms and Conditions {'(also referred as "Terms")'} mean these
                  Terms and Conditions that form the entire agreement between
                  You and the Company regarding the use of the Service.
                </p>
                <p>
                  Third-party Social Media Service means any services or content
                  (including data, information, products or services) provided
                  by a third-party that may be displayed, included or made
                  available by the Service.
                </p>
                <p>
                  Feedback means feedback, innovations or suggestions sent by
                  You regarding the attributes, performance or features of our
                  Service. Free Trial refers to a limited period of time that
                  may be free when purchasing a Subscription.
                </p>
                <p>
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable..
                </p>
                <b>Acknowledgment</b>
                <p>
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service
                </p>
                <p>
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                </p>
                <p>
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions then You may not access the
                  Service.
                </p>
                <p>
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the Service.
                </p>
                <p>
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use and disclosure of Your
                  personal information when You use the Application or the
                  Website and tells You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </p>

                <b>Subscriptions</b>

                <b>Subscription period</b>
                <p>
                  The Service or some parts of the Service are available only
                  with a paid Subscription. You will be billed in advance on a
                  recurring and periodic basis {"(such as monthly or annually)"}
                  , depending on the type of Subscription plan you select when
                  purchasing the Subscription. At the end of each period, Your
                  Subscription will automatically renew under the exact same
                  conditions unless You cancel it or the Company cancels it.
                </p>
                <b>Subscription cancellations</b>
                <p>
                  You may cancel Your Subscription renewal either through Your
                  Account settings page or by contacting the Company. You will
                  not receive a refund for the fees You already paid for Your
                  current Subscription period and You will be able to access the
                  Service until the end of Your current Subscription period.
                </p>
                <b>Billing</b>
                <p>
                  You shall provide the Company with accurate and complete
                  billing information including full name, address, state, zip
                  code, telephone number, and a valid payment method
                  information. Should automatic billing fail to occur for any
                  reason, the Company will issue an electronic invoice
                  indicating that you must proceed manually, within a certain
                  deadline date, with the full payment corresponding to the
                  billing period as indicated on the invoice.
                </p>
                <b>Fee Changes</b>
                <p>
                  The Company, in its sole discretion and at any time, may
                  modify the Subscription fees. Any Subscription fee change will
                  become effective at the end of the then-current Subscription
                  period. The Company will provide You with reasonable prior
                  notice of any change in Subscription fees to give You an
                  opportunity to terminate Your Subscription before such change
                  becomes effective. Your continued use of the Service after the
                  Subscription fee change comes into effect constitutes Your
                  agreement to pay the modified Subscription fee amount.
                </p>
                <b>Refunds</b>
                <p>
                  Except when required by law, paid Subscription fees are
                  non-refundable.
                </p>
                <p>
                  Certain refund requests for Subscriptions may be considered by
                  the Company on a case-by-case basis and granted at the sole
                  discretion of the Company.
                </p>
                <b>Free Trial</b>
                <p>
                  The Company may, at its sole discretion, offer a Subscription
                  with a Free trial for a limited period of time.
                </p>
                <p>
                  You may be required to enter Your billing information in order
                  to sign up for the Free trial.
                </p>
                <p>
                  If You do enter Your billing information when signing up for a
                  Free Trial, You will not be charged by the Company until the
                  Free trial has expired. On the last day of the Free Trial
                  period, unless You cancelled Your Subscription, You will be
                  automatically charged the applicable Subscription fees for the
                  type of Subscription You have selected.
                </p>
                <p>
                  At any time and without notice, the Company reserves the right
                  to (i) modify the terms and conditions of the Free Trial
                  offer, or (ii) cancel such Free trial offer.
                </p>
                <p>User Accounts</p>
                <p>
                  When You create an account with Us, You must provide Us
                  information that is accurate, complete, and current at all
                  times. Failure to do so constitutes a breach of the Terms,
                  which may result in immediate termination of Your account on
                  Our Service.
                </p>
                <p>
                  You are responsible for safeguarding the password that You use
                  to access the Service and for any activities or actions under
                  Your password, whether Your password is with Our Service or a
                  Third-Party Social Media Service.
                </p>
                <p>
                  You agree not to disclose Your password to any third party.
                  You must notify Us immediately upon becoming aware of any
                  breach of security or unauthorized use of Your account. You
                  may not use as a username the name of another person or entity
                  or that is not lawfully available for use, a name or trademark
                  that is subject to any rights of another person or entity
                  other than You without appropriate authorization, or a name
                  that is otherwise offensive, vulgar or obscene.
                </p>
                <b>Intellectual Property</b>
                <p>
                  The Service and its original content (excluding Content
                  provided by You or other users), features and functionality
                  are and will remain the exclusive property of the Company and
                  its licensors.
                </p>
                <p>
                  The Service is protected by copyright, trademark, and other
                  laws of both the Country and foreign countries.
                </p>
                <p>
                  Our trademarks and trade dress may not be used in connection
                  with any product or service without the prior written consent
                  of the Company.
                </p>
                <b>Your Feedback to Us</b>
              </p>
              <p>
                You assign all rights, title and interest in any Feedback You
                provide the Company. If for any reason such assignment is
                ineffective, You agree to grant the Company a non-exclusive,
                perpetual, irrevocable, royalty free, worldwide right and
                license to use, reproduce, disclose, sub-license, distribute,
                modify and exploit such Feedback without restriction.
              </p>
              <b>Links to Other Websites</b>
              <p>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by the Company.
              </p>
              <p>
                The Company has no control over, and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party web sites or services. You further acknowledge and agree
                that the Company shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any
                such content, goods or services available on or through any such
                web sites or services.
              </p>
              <p>
                We strongly advise You to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                You visit.
              </p>
              <b>Termination</b>
              <p>
                We may terminate or suspend Your Account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if You breach these Terms and Conditions.
              </p>
              <p>
                Upon termination, Your right to use the Service will cease
                immediately. If You wish to terminate Your Account, You may
                simply discontinue using the Service.
              </p>
              <b>Limitation of Liability</b>
              <p>
                Notwithstanding any damages that You might incur, the entire
                liability of the Company and any of its suppliers under any
                provision of this Terms and Your exclusive remedy for all of the
                foregoing shall be limited to the amount actually paid by You
                through the Service or 100 USD if You {"haven't"} purchased
                anything through the Service.
              </p>
              <p>
                To the maximum extent permitted by applicable law, in no event
                shall the Company or its suppliers be liable for any special,
                incidental, indirect, or consequential damages whatsoever
                (including, but not limited to, damages for loss of profits,
                loss of personal wealth, loss of data or other information, for
                business interruption, for personal injury, loss of privacy
                arising out of or in any way related to the use of or inability
                to use the Service, third-party software and/or third-party
                hardware used with the Service, or otherwise in connection with
                any provision of this Terms), even if the Company or any
                supplier has been advised of the possibility of such damages and
                even if the remedy fails of its essential purpose.
              </p>
              <p>
                To the maximum extent permitted by applicable law, in no event
                shall the Company or its suppliers be liable for any special,
                incidental, indirect, or consequential damages whatsoever
                (including, but not limited to, damages for loss of profits,
                loss of personal wealth, loss of data or other information, for
                business interruption, for personal injury, loss of privacy
                arising out of or in any way related to the use of or inability
                to use the Service, third-party software and/or third-party
                hardware used with the Service, or otherwise in connection with
                any provision of this Terms), even if the Company or any
                supplier has been advised of the possibility of such damages and
                even if the remedy fails of its essential purpose.
              </p>
              <b>Disclaimers</b>
              <p>Service and Website Disclaimer</p>
              <p>
                All Signals provided on the Website are impersonal, general in
                nature and are made regardless of an individual’s degree of
                sophistication with regards to investment experience,
                preferences, objectives or risk appetite, AND without regards to
                the suitability of the Signal for use by individuals or entities
                who may access it.
              </p>
              <p>
                The Signals found on the Website and any Services provided by
                the Company, should not be interpreted as suggestions to buy or
                Sell any security or investment vehicle; nor should they be
                construed as being tailored or specific to You.
              </p>
              <p>
                The trading signals provided are based on our proprietary
                algorithmic trading system. These signals are intended for
                informational purposes only and do not constitute financial,
                investment, or trading advice. While we strive for accuracy, we
                do not guarantee the profitability or performance of the
                signals. The stock market is inherently risky, and you should be
                aware that past performance is not indicative of future results.
              </p>
              <p>
                You acknowledge that trading in securities involves a high
                degree of risk, including the potential loss of your entire
                investment. You should trade only with funds that you can afford
                to lose.
              </p>
              <p>
                Your decision to act on any trading signal is at your sole
                discretion and responsibility. We recommend consulting with a
                financial advisor or conducting your own due diligence before
                making any investment decisions.
              </p>
              <p>
                We shall not be liable for any losses or damages incurred as a
                result of using our trading signals. This includes, but is not
                limited to, direct, indirect, incidental, consequential, or
                punitive damages.
              </p>
              <p>
                Market conditions, news, and other external factors can impact
                the effectiveness of trading signals. Our system may not account
                for all such factors, and you should be aware of market
                volatility.
              </p>
              <p>
                We reserve the right to modify, suspend, or discontinue the
                provision of trading signals at any time without notice.
              </p>
              <p>
                Our algorithmic system relies on technology, which may be
                subject to technical issues or errors. We are not responsible
                for any loss or damage arising from such technological failures.
              </p>
              <p>
                You are responsible for ensuring that your use of the trading
                signals complies with all applicable laws and regulations in
                your jurisdiction.
              </p>
              <p>
                By using our trading signals, you acknowledge that you have
                read, understood, and agree to this disclaimer.
              </p>
              <p>
                Some jurisdictions do not allow the exclusion of certain types
                of warranties or limitations on applicable statutory rights of a
                consumer, so some or all of the above exclusions and limitations
                may not apply to You. But in such a case the exclusions and
                limitations set forth in this section shall be applied to the
                greatest extent enforceable under applicable law.
              </p>
              <p>
                You must fully understand and accept these terms before using
                our services.
              </p>
              <b>Governing Law</b>
              <p>
                The laws of the Country, excluding its conflicts of law rules,
                shall govern this Terms and Your use of the Service. Your use of
                the Application may also be subject to other local, state,
                national, or international laws.
              </p>
              <b>Disputes Resolution</b>
              <p>
                If You have any concern or dispute about the Service, You agree
                to first try to resolve the dispute informally by contacting the
                Company.
              </p>
              <b>For European Union (EU) Users</b>
              <p>
                If You are a European Union consumer, you will benefit from any
                mandatory provisions of the law of the country in which you are
                resident in.
              </p>
              <b>United States Federal Government End Use Provisions</b>
              <p>
                If You are a U.S. federal government end user, our Service is a
                {'"Commercial Item"'} as that term is defined at 48 C.F.R.
                §2.101.
              </p>
              <b>United States Legal Compliance</b>
              <p>
                You represent and warrant that (i) You are not located in a
                country that is subject to the United States government embargo,
                or that has been designated by the United States government as a
                {'"terrorist supporting"'} country, and (ii) You are not listed
                on any United States government list of prohibited or restricted
                parties.
              </p>
              <b>Severability and Waiver</b>
              <b>Severability</b>
              <p>
                If any provision of these Terms is held to be unenforceable or
                invalid, such provision will be changed and interpreted to
                accomplish the objectives of such provision to the greatest
                extent possible under applicable law and the remaining
                provisions will continue in full force and effect.
              </p>
              <b>Waiver</b>
              <p>
                Except as provided herein, the failure to exercise a right or to
                require performance of an obligation under this Terms shall not
                effect a {"party's"} ability to exercise such right or require
                such performance at any time thereafter nor shall be the waiver
                of Translation Interpretation
              </p>
              <p>
                These Terms and Conditions may have been translated if We have
                made them available to You on our Service. You agree that the
                original English text shall prevail in the case of a dispute.
              </p>
              <b>Changes to These Terms and Conditions</b>
              <p>
                We reserve the right, at Our sole discretion, to modify or
                replace these Terms at any time. If a revision is material We
                will make reasonable efforts to provide at least 30 {"days'"}{" "}
                notice prior to any new terms taking effect. What constitutes a
                material change will be determined at Our sole discretion.
              </p>
              <p>
                By continuing to access or use Our Service after those revisions
                become effective, You agree to be bound by the revised terms. If
                You do not agree to the new terms, in whole or in part, please
                stop using the website and the Service.
              </p>
              <p>Contact Us</p>
              <p>
                If you have any questions about these Terms and Conditions, You
                can contact us by email: info@cygnuscues.com a breach constitute
                a waiver of any subsequent breach.
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
