import { MainLayout } from "../layout/MainLayout";

export const FreeTrial = () => {
  return (
    <MainLayout>
      {/* // <!-- section content begin --> */}
      <div className="uk-section uk-section-muted in-padding-large-vertical@s in-profit-9">
        <div className="uk-container">
          <div className="uk-grid-divider" data-uk-grid>
            <div className="uk-width-expand@m in-margin-top-20@s">
              <h3>Your Free Trial Includes:</h3>
              <p>Realtime Trade Signals on over 300 Stock and ETF symbols</p>
              <p>30 days access to both Buy and sell side trades</p>
              <p>Up to two open signals a day</p>
              <p>*Maximum 20 open signals at a time</p>

              <p>
                {"("}Open means system sends Buy/Sell signal,but {"hasn’t"} sent
                corresponding Sell/Buy signals.{")"}
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
