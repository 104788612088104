import { useMsal } from "@azure/msal-react";
import { Button } from "uikit-react";
import { signupRequest } from "../authConfig";

export const SignUpButton = () => {
  const { instance } = useMsal();

  const handleSignUp = (signUpType: string) => {
    if (signUpType === "popup") {
      instance.loginPopup(signupRequest);
    } else if (signUpType === "redirect") {
      instance.loginRedirect(signupRequest);
    }
  };

  return (
    <Button
      className="register"
      onClick={() => handleSignUp("popup")}
      key="loginRedirect"
    >
      Register
    </Button>
  );
};
