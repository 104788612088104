import { Footer } from "./main/Footer";
import Header from "./main/Header";

export interface LayoutProps {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      <main children={children}></main>
      <Footer />
    </>
  );
};
